<template>
  <div>
    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
      v-model="vDataTable.selected"
      :headers="headers"
      :items="vDataTable.data"
      :search="vDataTable.search"
      show-select
      fixed-header
      :page.sync="vDataTable.options.page"
      :itemsPerPage.sync="vDataTable.options.itemsPerPage"
      :options.sync="vDataTable.options"
      :height="$vuetify.breakpoint.height - 123 - offsetAppBar"
      :footer-props="{
        itemsPerPageText: $t('itemsPerPageText'),
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      item-key="win_skey"
      class="fill-height">
      <template v-slot:header.win_name="{ header }">
        {{ header.text.toUpperCase() }}
      </template>
      <template v-slot:header.data-table-select="{ on, props }">
        <tr>
          <td>
            <v-simple-checkbox :ripple="false" v-bind="props" v-on="on"></v-simple-checkbox>
          </td>
          <td v-if="vDataTable.selected.length > 0">
            {{vDataTable.selected.length}}
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Window</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog.dialogAddEdit" max-width="500px" eager>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize">
                <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('navWindow.buttonNewWindow')}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">  
                      <v-text-field error-messages :label="$t('navWindow.windowName')" v-model="vDataTable.editedItem.win_name" 
            :rules="[rules.winName]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field :label="$t('navWindow.windowComment')" v-model="vDataTable.editedItem.window_comment"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn outlined color="primary" @click="close">{{$t('cancel')}}</v-btn>
                  <v-btn outlined color="primary" @click="save">{{$t('save')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" @click="checkDeleteselected" style="text-transform: capitalize">
            <img src="../../assets/SoftwareClinicIcon/Delete.png" height="30" weight="30"/>{{$t('navWindow.buttonDeleteWindow')}}
          </v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" style="text-transform: capitalize" @click="retrieveWindow(appName)">
            <img src="../../assets/SoftwareClinicIcon/Find.png" height="30" weight="30"/>{{$t('find')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox :value="isSelected" :ripple="false" @input="select($event)" ></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="teal" small class="mr-2" v-bind="attrs" v-on="on" @click="editItem(item)">edit</v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink" small class="mr-2" v-bind="attrs" v-on="on" @click="deleteItemConfirm(item)">delete</v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-no="{ item, index }">
        {{((vDataTable.options.page - 1) * vDataTable.options.itemsPerPage) + index + 1}}
      </template>
      <template v-slot:no-data>
        <v-alert type="info">
          {{$t('noData')}}
        </v-alert>
      </template>
      <template v-slot:no-results>
        <v-alert type="warning">
          {{stringFormat($t('searchNotFound'),vDataTable.search)}}
        </v-alert>
      </template>
      <template v-slot:footer>
        <tr class="sticky-table-footer" style="position: absolute">
          <td>Selected({{vDataTable.selected.length}})s</td> 
        </tr>
      </template>
    </v-data-table> 
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
export default {
  components: {
  },

  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      rules: {
        winName: value => {
          const pattern = /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/
          return pattern.test(value) || 'Invalid Window Name'
        },
      },
      dialog: {
        dialogProgress: false,
        dialogAddEdit: false,
      },
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
      },
      progressMessage: '',
      appSkey: -1,
    }
  },

  computed: {
    ...mapGetters(['getShowAppBar', 'getAppBarHeight']),
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? this.$t('navWindow.newWindow') : this.$t('navWindow.editWindow')
    },
    headers () {
      return [
        { text: '#', align: 'center', value: 'data-table-no' },
        { text: '', align: 'center', value: 'data-table-select' },
        { text: 'win_skey', align: 'left', value: 'win_skey' },
        { text: this.$t('actions'), value: 'actions', sortable: false },
        { text: this.$t('navWindow.windowName'), value: 'win_name', align: 'right' },
        { text: this.$t('navWindow.windowComment'), value: 'window_comment', align: 'left' },
        { text: this.$t('createUser'), value: 'create_user_id', align: 'left' },
        { text: this.$t('createDate'), value: 'create_date', align: 'left' },
        { text: this.$t('maintUser'), value: 'maint_user_id', align: 'left' },
        { text: this.$t('maintDate'), value: 'maint_date', align: 'left' },
        { text: 'File', value: 'win_dll_name', align: 'left' },
      ]
    },
    offsetAppBar () {
      return this.getShowAppBar ? this.getAppBarHeight : 0
    }
  },

  watch: {
    'dialog.dialogAddEdit' (val) {
      val || this.close()
    }
  },

  async created () {
    await this.retrieveWindow(this.appName)
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    selectLanguage (language) {
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        win_name: '',
        window_comment: ''
      }
      this.vDataTable.defaultItem = {
        win_name: '',
        window_comment: ''
      }
    },
    async retrieveWindow (appName) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveWindow' + process.env.VUE_APP_DATABASE_TYPE, {
        app_name: appName
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.appSkey = response.data.data.length > 0 ? response.data.data[0]['app_skey'] : -1
            this.vDataTable.data = response.data.data
            this.initialize()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async addWindow (appSkey, winName, windowComment) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/navigation/addWindow' + process.env.VUE_APP_DATABASE_TYPE, {
        app_skey: appSkey,
        win_name: winName,
        window_comment: windowComment
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.editedItem.app_skey = response.data.data.app_skey
            this.vDataTable.editedItem.win_skey = response.data.data.win_skey
            this.vDataTable.data.push(this.vDataTable.editedItem)
            this.$swal({type: 'success', title: response.data.reasonText})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async updateWindow (winSkey, winName, windowComment) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/navigation/updateWindow' + process.env.VUE_APP_DATABASE_TYPE, {
        win_skey: winSkey,
        win_name: winName,
        window_comment: windowComment
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.vDataTable.data[this.vDataTable.editedIndex], this.vDataTable.editedItem)
            this.$swal({type: 'success', title: response.data.reasonText})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    checkDeleteselected () {
      if (this.vDataTable.selected.length > 0) {
        this.$swal({
          text: this.$t('navWindow.confirmDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteSelected()
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('navWindow.messageDeleteNotSelected')})
      }
    },
    async deleteSelected () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.vDataTable.selected, async function (x) {
        this.progressMessage = this.stringFormat(this.$t('navWindow.progressMessageDelete'), x.win_name)
        var val = await this.deleteWindow(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))

      // del.forEach(x => {
      //   this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(x), 1)
      // })

      for (const x in del) {
        this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(x), 1)
      }

      this.dialog.dialogProgress = false
      // this.retrieveWindow(this.appName)
    },
    async deleteWindow (item) {
      try {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var winSkey = item.win_skey
        var valRet
        await this.axios.post(process.env.VUE_APP_API + '/navigation/deleteWindow' + process.env.VUE_APP_DATABASE_TYPE, {
          win_skey: winSkey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              if (this.vDataTable.data.indexOf(item) >= 0) {
                this.vDataTable.data.splice(this.vDataTable.data.indexOf(item), 1)
              }
              this.$swal({type: 'success', title: response.data.reasonText})
              valRet = item
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
        return valRet
      } catch (e) {
        await this.$swal({type: 'error', title: e.message})
      } finally {
        this.dialog.dialogProgress = false
      }
    },
    editItem (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.dialog.dialogAddEdit = true
    },
    async deleteItemConfirm (item) {
      // this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      // this.vDataTable.editedItem = Object.assign({}, item)
      this.$swal({
        text: this.stringFormat(this.$t('navWindow.confirmDelete'), item.win_name),
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          // var val = await this.deleteWindow(this.vDataTable.editedItem)
          var val = await this.deleteWindow(item)
          if (val !== undefined) {
            if (this.vDataTable.selected.indexOf(item) >= 0) {
              this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(item), 1)
            }
          }
          // this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
          // this.vDataTable.editedIndex = -1
        }
      })
    },
    close () {
      this.dialog.dialogAddEdit = false
      this.$nextTick(() => {
        this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
        this.vDataTable.editedIndex = -1
      })
    },
    async save () {
      if (this.vDataTable.editedIndex > -1) {
        await this.updateWindow(this.vDataTable.editedItem.win_skey, this.vDataTable.editedItem.win_name, this.vDataTable.editedItem.window_comment)
      } else {
        await this.addWindow(this.appSkey, this.vDataTable.editedItem.win_name, this.vDataTable.editedItem.window_comment)
      }
      this.close()
    }
  },
}
</script>
<style scoped>
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  border-top: thin solid rgba(0,0,0,.12);
}
</style>